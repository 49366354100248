import { makeStyles, Theme } from "@material-ui/core";
//import constants from '../../../styles/constants'

const styles = makeStyles((theme: Theme) => ({
  buttonBase: {
    //height: 45,
    minWidth: 55,
    padding: 15,
    borderRadius: "30%",
    fontSize: 18,
    transition: "all 0.3s ease-out",
    "&:hover": {
      cursor: "pointer",
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: "100%",
      padding: 12,
      fontSize: 16,
    },
    [theme.breakpoints.down("xs")]: {
      padding: "14px 10px",
      fontSize: 14,
    },
  },
  available: {
    border: "1px solid #CECECE",
    "&:hover": {
      backgroundColor: "#FAFAFA",
    },
  },
  selected: {
    backgroundColor: theme.palette.primary.main,
    border: "1px solid " + theme.palette.primary.main,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
    "&:active": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  today: {
    border: "2px solid #019ee59c",
  },
  unavailable: {
    color: "#909090",
    textDecoration: "line-through",
  },
}));

export default styles;
