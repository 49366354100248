import { Paper } from "@material-ui/core";
import { ReactNode } from "react";
import useStyles from "./BaseLayout.styles";

export type Props = {
  children: ReactNode;
};

const BaseLayout = ({ children }: Props) => {
  const classes = useStyles();

  return <Paper className={classes.root}>{children}</Paper>;
};

export default BaseLayout;
