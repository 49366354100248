import { FunctionComponent, useState, useCallback, useEffect } from "react";
import { Box, makeStyles, TextField } from "@material-ui/core";

import _debounce from "lodash/debounce";
import { PhoneNumber } from "../../../types/bookingTypes";

export type Props = {
  initValue: PhoneNumber;
  onChange: (value: PhoneNumber) => void;
};

const PhoneNumberInputField: FunctionComponent<Props> = ({
  initValue,
  onChange,
}) => {
  const [nationalNumber, setNationalNumber] = useState(
    initValue.nationalNumber ?? ""
  );
  const [countryCode, setCountryCode] = useState(initValue.countryCode ?? "");

  const handleChange = (value: string) => {
    setNationalNumber(value);
  };

  const debounced = useCallback(
    _debounce(() => {
      onChange({ countryCode: countryCode, nationalNumber: nationalNumber });
    }, 500),
    [nationalNumber, countryCode]
  );

  useEffect(() => {
    debounced();

    // Clean up on unmounting
    return debounced.cancel;
  }, [nationalNumber, countryCode, debounced]);

  return (
    <Box style={{ display: "flex" }}>
      <TextField
        name="countryCode"
        label="Landskode"
        value={countryCode}
        onChange={(e) => setCountryCode(e.target.value)}
        autoComplete="tel-country-code"
        aria-label="Landskode"
        style={{ marginRight: 10, maxWidth: 100 }}
        variant="outlined"
      />
      <TextField
        name="nationalNumber"
        data-cy="customer-phone-field"
        label="Telefonnummer"
        value={nationalNumber}
        fullWidth
        onChange={(e) => handleChange(e.target.value)}
        autoComplete="tel-national"
        aria-label="Telefonnummer"
        variant="outlined"
      />
    </Box>
    // <MuiPhoneNumber
    //   className={styles.root}
    //   label="Telefonnummer"
    //   defaultCountry={"no"}
    //   value={value}
    //   name="phoneNumber"
    //   onChange={handleChange}
    //   autoComplete="tel"
    //   data-cy="phoneNumber-field"
    // />
  );
};
const useStyles = makeStyles(() => ({
  root: {
    padding: 6,
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: 4,
    "& > label": {
      top: -8,
      left: 7,
      display: "inline-block",
      background: "white",
      padding: "0px 4px",
    },
    "& > div": {
      padding: 5,
      marginTop: "inherit",
    },
    "& .MuiInput-underline:after, .MuiInput-underline:before": {
      display: "none",
    },
  },
  phoneLabel: {},
  phoneInput: {},
}));

export default PhoneNumberInputField;
