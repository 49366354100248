import { FunctionComponent } from 'react'
import { Button, makeStyles, Theme } from '@material-ui/core'
import { BookingElementType } from '../../../types/bookingTypes'

export type Props = {
    types: BookingElementType[],
    handleAddBookingElement: (event: any) => void,
}

const AddBookingElementButton: FunctionComponent<Props> = ({ types, handleAddBookingElement }) => {
    const styles = useStyles()

    return (
        <div className={styles.root}>
            {types && types.map(type => {
                return (
                    <Button
                        key={type.name}
                        value={type.name}
                        variant='contained'
                        className={styles.button}
                        name={'booking-type:' + type.name}
                        onClick={handleAddBookingElement}
                        disableRipple
                        aria-label={'Legg til booking-element'}
                        data-cy={'add-' + type.name}
                    >
                        {type.addButtonText}
                    </Button>)
            })}
        </div>
    )
}
const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '50%',
        [theme.breakpoints.down(500)]: {
            maxWidth: 'none',
        }
    },
    button: {
        backgroundColor: '#F7F7F7',
        color: '#7C7C7C',
        padding: '15px 22px',
        '&:not(:first-child)': {
            marginTop: 10,

        }
    },
}))

export default AddBookingElementButton