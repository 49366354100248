import { Checkbox, FormControlLabel } from "@material-ui/core";
import { FunctionComponent } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "../../hooks/useSelector";
import { acceptedTerms } from "../../state/ducks/booking";

type Props = {};

const OrderTermsCheckboxContainer: FunctionComponent<Props> = () => {
  const dispatch = useDispatch();
  const checked = useSelector(
    (state) => state.bookingReducer.booking.acceptedTerms
  );

  const handleClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(acceptedTerms(event.target.checked));
  };

  return (
    <FormControlLabel
      control={<Checkbox onChange={handleClick} checked={checked} />}
      label="Jeg godtar verkstedsbetingelsene"
      data-cy="accept-conditions"
    />
  );
};

export default OrderTermsCheckboxContainer;
