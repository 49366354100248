import { FunctionComponent } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Theme,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { useDispatch } from "react-redux";

import BikeboxLogo from "../../static/bikebox-logo.png";
import Logo from "../../components/Graphics/Logo";
import { useSelector } from "../../hooks/useSelector";
import { setBookingElementInfo } from "../../state/ducks/booking";

interface Props {
  bikeboxQty: number;
}

const BikeboxBookingContainer: FunctionComponent<Props> = ({ bikeboxQty }) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const bookingElements = useSelector(
    (state) => state.bookingReducer.booking.bookingElements
  );

  const totalBikeboxes = bookingElements.filter((el) => el.bikebox).length;

  const setBikeBoxOnBookingElement = (id: string) => {
    const element = bookingElements.find((el) => el.id === id);
    if (element) {
      const bikebox = element.bikebox ? undefined : true;
      dispatch(setBookingElementInfo("bikebox", bikebox, id));
    }
  };

  return (
    <Box className={styles.root} data-cy="bikebox-container">
      <div style={{ maxWidth: "30%", minWidth: "200px" }}>
        <Logo logoPath={BikeboxLogo} />
      </div>
      <Typography variant="body1" className={styles.boldBodyText}>
        Nå kan du levere sykler utenfor åpningstid!
      </Typography>
      <Typography variant="body1">
        Med mobilen din kan du enkelt levere sykkelen akkurat når det passer
        deg, opp til to dager før din service!
      </Typography>
      <div>
        <Button variant="outlined">Les mer om systemet her</Button>
      </div>
      <Typography variant="body1" className={styles.boldBodyText}>
        Ønsker du å levere i sykkelboks?
      </Typography>
      <Box className={styles.bikeboxCheckmarks}>
        {bookingElements.map((el, i) => {
          const disabled = el.bikebox !== true && totalBikeboxes >= bikeboxQty;
          return (
            <FormControl key={el.id}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={disabled}
                    onChange={() => setBikeBoxOnBookingElement(el.id)}
                    checked={el.bikebox === true ? true : false}
                  />
                }
                label={`${i + 1} – ${el.brand?.name}`}
                data-cy={"accept-bikebox-" + i}
              />
              {disabled && (
                <FormHelperText>
                  Kun {bikeboxQty} sykkelboks{bikeboxQty > 1 && "er"} er ledig.
                  Som regel kan du få plass til 2 sykler i en boks, men det er
                  ikke noe vi kan garantere.
                </FormHelperText>
              )}
            </FormControl>
          );
        })}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: 20,
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
  logoContainer: {
    maxWidth: "30%",
    minWidth: "200px",
  },
  boldBodyText: {
    fontWeight: 700,
  },
  bikeboxCheckmarks: {
    display: "flex",
    flexDirection: "column",
  },
}));

export default BikeboxBookingContainer;
