import { FunctionComponent } from "react";
import {
  Box,
  CircularProgress,
  makeStyles,
  Typography,
} from "@material-ui/core";

export type Props = {
  text?: string;
};

const LoadingSpinner: FunctionComponent<Props> = ({ text = "Laster..." }) => {
  const styles = useStyles();

  return (
    <Box className={styles.root}>
      <CircularProgress />
      <Typography className={styles.loadingText} variant="subtitle1">
        {text}
      </Typography>
    </Box>
  );
};
const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#f9f9f9",
    borderRadius: 10,
    height: "100%",
    padding: 30,
  },
  loadingText: {
    marginTop: 10,
  },
}));

export default LoadingSpinner;
