import { FunctionComponent } from "react";
import { makeStyles, Theme } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";

export type Props = {
  label: string;
  value: string;
  dropdownItems: { key: string | number; value: string }[];
  onChange: (value: any) => void;
  error?: boolean;
  errorText?: string;
};

const DropdownMenu: FunctionComponent<Props> = ({
  label,
  value,
  dropdownItems,
  onChange,
  error,
  errorText,
}) => {
  const classes = useStyles();

  const handleChange = (event: any) => {
    onChange(event.target.value);
  };

  return (
    <>
      <FormControl
        variant="outlined"
        className={classes.formControl}
        error={error}
      >
        <InputLabel>{label}</InputLabel>
        <Select
          value={value}
          onChange={handleChange}
          label="Velg merke"
          className={classes.menuItem}
          data-cy="dropdown-choose-brand"
        >
          {dropdownItems &&
            dropdownItems.length > 0 &&
            dropdownItems.map((el) => {
              return (
                <MenuItem
                  key={el.key}
                  value={el.key}
                  data-cy={el.key + "-menu-item"}
                >
                  {el.value}
                </MenuItem>
              );
            })}
        </Select>
        {errorText && <FormHelperText>{errorText}</FormHelperText>}
      </FormControl>
    </>
  );
};
const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  formControl: {
    minWidth: 140,
  },
  line: {
    border: "1px solid #eaeaea",
    margin: "5px auto",
    width: "90%",
  },
  menuItem: {
    "& :last-child()": {
      backgroundColor: "#ff0000",
    },
  },
}));

export default DropdownMenu;
