import { Box, Button, Typography } from "@material-ui/core";
import { Check } from "@material-ui/icons";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useDeleteBookingMutation } from "../../../services/MutateBooking";
import { BookingDTO } from "../../../types/bookingTypes";
import Modal from "../Modal";
import makeStyles from "./CancelBookingModal.styles";

type Props = {
  open: boolean;
  toggleModal: () => void;
  storeName: string;
  booking: BookingDTO;
};

const CancelBookingModal = ({
  open,
  toggleModal,
  storeName,
  booking,
}: Props) => {
  const styles = makeStyles();
  const navigate = useNavigate();

  const [
    deleteBooking,
    { isLoading: deletingBooking, isSuccess: deletingBookingSuccess },
  ] = useDeleteBookingMutation();

  const handleDeleteBooking = () => {
    if (booking.hashedId) {
      deleteBooking({ hashedId: booking.hashedId, storeName });
    }
  };

  useEffect(() => {
    if (deletingBookingSuccess) {
      setTimeout(() => {
        navigate("/");
      }, 1250);
    }
  }, [deletingBookingSuccess]);

  return (
    <Modal openModal={open} toggleModal={toggleModal} maxWidth={"sm"}>
      <Box
        style={{
          padding: 30,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        {!deletingBooking && deletingBookingSuccess ? (
          <Box>
            <Box display={"flex"} flexDirection="row">
              <Typography variant="h2" style={{ marginRight: 5 }}>
                Timen er avbestilt.
              </Typography>
              <Check color="secondary" fontSize="default" />
            </Box>
            <Typography style={{ marginTop: 10 }}>
              Du blir nå sendt tilbake til forsiden.
            </Typography>
          </Box>
        ) : (
          <>
            <div>
              <Typography variant="h2" style={{ marginBottom: 20 }}>
                Bekreft avbestilling av time
              </Typography>
              <Typography>Timen din blir slettet fra vårt system.</Typography>
            </div>
            <Box className={styles.buttonBox}>
              <Button
                variant="outlined"
                onClick={() => toggleModal()}
                disabled={deletingBooking}
              >
                Avbryt
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{ color: "white" }}
                onClick={handleDeleteBooking}
                disabled={deletingBooking}
              >
                Avbestill time
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default CancelBookingModal;
