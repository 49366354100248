import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  root: {
    minHeight: "75vh",
    maxWidth: theme.breakpoints.values.md - 60,
    display: "flex",
    flexDirection: "column",
    alignSelf: "center",
    justifyContent: "space-between",
    padding: "30px 150px",
    margin: "30px auto",
    [theme.breakpoints.down("md")]: {
      maxWidth: "80vw",
      padding: "30px 50px",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      boxShadow: "none",
      paddingBottom: 80,
      padding: 20,
      margin: 0,
    },
    [theme.breakpoints.down("xs")]: {
      paddingBottom: 70,
      padding: 10,
    },
  },
}));
