import { FunctionComponent } from "react";
import { ButtonBase, Tooltip } from "@material-ui/core";
import { isToday } from "date-fns";
import clsx from "clsx";

import useStyles from "./CalendarBlob.styles";

export type Props = {
  date: Date;
  availableResources: number | null;
  selected: boolean;
  onClick: () => void;
};

const CalendarBlob: FunctionComponent<Props> = ({
  date,
  availableResources,
  selected,
  onClick,
}) => {
  const styles = useStyles();

  const disabledButton: boolean =
    !availableResources || availableResources < 1 || isToday(date);

  let buttonStateClasses;
  // Button states styling
  if (selected) {
    // Show selected CSS
    buttonStateClasses = styles.selected;
  } else if (date && isToday(date)) {
    // Show today CSS
    buttonStateClasses = clsx(styles.today, styles.unavailable);
  } else if (disabledButton) {
    // Show no available CSS
    buttonStateClasses = styles.unavailable;
  } else {
    buttonStateClasses = styles.available;
  }

  const toolTipText =
    isToday(date) || (availableResources && availableResources < 1)
      ? "Fullbooket"
      : `Ledige plasser: ${availableResources}`;

  return (
    <>
      {availableResources === null ? (
        <ButtonBase
          data-cy={`calendar-day-past`}
          disabled
          className={clsx(styles.buttonBase, styles.unavailable)}
        >
          {date.getDate()}
        </ButtonBase>
      ) : (
        <Tooltip arrow title={toolTipText}>
          <span>
            <ButtonBase
              data-cy={
                disabledButton ? `calendar-day-disabled` : `calendar-day-active`
              }
              focusRipple
              disabled={disabledButton}
              onClick={onClick}
              className={clsx(styles.buttonBase, buttonStateClasses)}
            >
              {date.getDate()}
            </ButtonBase>
          </span>
        </Tooltip>
      )}
    </>
  );
};

export default CalendarBlob;
