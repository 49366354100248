import { configureStore } from "@reduxjs/toolkit";

import calendarReducer from "./ducks/calendar";
import bookingReducer from "./ducks/booking";
import stepperReducer from "./ducks/stepper";

import { api, rtkQueryErrorLogger } from "../services/api";

const store = configureStore({
  reducer: {
    bookingReducer,
    calendarReducer,
    stepperReducer,
    [api.reducerPath]: api.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware).concat(rtkQueryErrorLogger),
});

interface CypressWithStore extends Cypress.Cypress {
  store?: typeof store;
}

declare global {
  interface Window {
    Cypress?: CypressWithStore;
  }
}

// expose store when run in Cypress
if (typeof window !== "undefined" && window.Cypress) {
  window.Cypress.store = store;
}

export type RootState = ReturnType<typeof store.getState>;

export default store;
