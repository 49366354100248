import { FunctionComponent } from "react";
import { makeStyles } from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

export type Props = {
  errorDescription: string;
};

const ErrorMessageCard: FunctionComponent<Props> = ({ errorDescription }) => {
  const styles = useStyles();

  return (
    <div className={styles.root} data-cy="error-message-card">
      <ErrorOutlineIcon />
      <p className={styles.text}>{errorDescription}</p>
    </div>
  );
};
const useStyles = makeStyles(() => ({
  root: {
    padding: "10px 15px",
    margin: "10px 0",
    backgroundColor: "#ffeaea",
    display: "flex",
    alignItems: "center",
  },
  text: {
    fontWeight: 500,
    marginLeft: 10,
    lineHeight: "1.5em",
  },
}));

export default ErrorMessageCard;
