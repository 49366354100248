import { createTheme } from "@material-ui/core/styles";

const PRIMARY_COLOR = "#019EE5";

const theme = (primaryColor?: string) =>
  createTheme({
    palette: {
      primary: {
        main: primaryColor ?? "#019EE5",
      },
      secondary: {
        main: "#19b826",
      },
      error: {
        main: "#D1492A",
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
    typography: {
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      h1: {
        fontSize: "1.65rem",
        fontWeight: 700,
        color: primaryColor ?? PRIMARY_COLOR,
      },
      h2: {
        fontSize: "1.3rem",
        fontWeight: 700,
      },
      h3: {
        fontSize: "1.1rem",
        fontWeight: 500,
      },
      body1: {
        fontSize: "1rem",
      },
      body2: {
        fontSize: "0.8rem",
      },
      button: {
        textTransform: "none",
        fontSize: "1rem",
        fontWeight: 600,
      },
    },
  });

export default theme;
