import { FunctionComponent, useEffect, useState } from "react";
import {
  Box,
  Button,
  FormHelperText,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import FormControl from "@material-ui/core/FormControl";

import OrderTermsCheckboxContainer from "../../../../containers/OrderTermsCheckboxContainer/OrderTermsCheckboxContainer";
import { useSelector } from "../../../../hooks/useSelector";
import LoadingSpinner from "../../../../components/Indicators/LoadingSpinner/LoadingSpinner";
import DropdownMenu from "../../../../components/FormControls/DropdownMenu/DropdownMenu";
import { projectId } from "../../../../configs/firebase";
import { usePostPromoCodeMutation } from "../../../../services/PromoCode";
import { verifyPromoCodeSuccess } from "../../../../state/ducks/booking";

export type Props = {};

const OrderTerms: FunctionComponent<Props> = () => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const { storeOptions, serviceDetails } = useSelector(
    (state) => state.bookingReducer.resources
  );
  const storeOption = useSelector(
    (state) => state.bookingReducer.booking.storeOption
  );
  const stepError = useSelector((state) => state.stepperReducer.stepError);

  const [promoCode, setPromoCode] = useState("");

  const [verifiedPromoCode, setVerifiedPromoCode] = useState(false);

  const termsOfConditions = serviceDetails?.termsOfConditions;

  const usePromoCode = storeOption.promoCodesActive;

  const handleChangeStoreOption = (value: string) => {
    const option = storeOptions.find((el) => el.id === value);

    if (option) {
      dispatch({
        type: "SET_STORE_OPTION",
        payload: { storeOption: option },
      });
    }
  };

  const [verifyPromoCode, { isSuccess, isLoading, data, error }] =
    usePostPromoCodeMutation();

  useEffect(() => {
    if (storeOptions && storeOptions.length === 1)
      handleChangeStoreOption(storeOptions[0].id);
  }, [storeOptions]);

  const stopreOptionsDropdownItems = storeOptions?.map((el) => {
    return { key: el.id, value: el.name };
  });

  const stepErrorId =
    stepError?.description !== undefined ? stepError.id : null;

  useEffect(() => {
    if (isSuccess && data?.error === null) {
      setVerifiedPromoCode(true);
      dispatch(verifyPromoCodeSuccess(data.result));
    } else if (isSuccess && data?.error !== null) {
      setVerifiedPromoCode(false);
      dispatch(verifyPromoCodeSuccess(null));
    }
  }, [isSuccess, data?.error]);

  return (
    <div className={styles.root}>
      <div className={styles.termsContainer}>
        {storeOptions && storeOptions.length > 1 && (
          <DropdownMenu
            label="Velg butikk"
            onChange={handleChangeStoreOption}
            value={storeOption.id}
            dropdownItems={stopreOptionsDropdownItems}
            error={stepErrorId === "NO_STORE_OPTION"}
          />
        )}

        {termsOfConditions ? (
          <>
            {termsOfConditions &&
              termsOfConditions.map((paragraphText, i) => {
                return (
                  <Typography key={i} variant="body1" paragraph>
                    {paragraphText}
                  </Typography>
                );
              })}
            {projectId === "trek-bergen-345412" && (
              <Typography>
                Verkstedet vårt reparerer ikke visse sykler -{" "}
                <a
                  href="https://trekbergen.no/verksted"
                  target="_blank"
                  rel="noreferrer"
                >
                  du kan lese mer om dette her.
                </a>
              </Typography>
            )}
            <div className={styles.acceptTermsButton}>
              <FormControl error={stepErrorId === "NOT_ACCEPTED_TERMS"}>
                <OrderTermsCheckboxContainer />
              </FormControl>
            </div>
          </>
        ) : (
          <LoadingSpinner text="Henter info..." />
        )}
      </div>
      {usePromoCode && (
        <Box className={styles.promoCodeContainer}>
          {/* <Typography variant="h3" style={{ marginBottom: 5 }}>
            Bruk kampanjekode
          </Typography> */}
          <FormControl data-cy="promo-code-form" error>
            <TextField
              variant="outlined"
              id="promo-code-input"
              data-cy="promo-code-field"
              name="promoCode"
              label="Kampanjekode"
              autoComplete="off"
              value={promoCode}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  if (promoCode !== "")
                    verifyPromoCode({ storeName: storeOption.id, promoCode });
                }
              }}
              onChange={(e) => {
                setPromoCode(e.target.value);
                setVerifiedPromoCode(false);
              }}
            />
            {verifiedPromoCode && (
              <FormHelperText style={{ color: " green" }}>
                Koden er bekreftet!
              </FormHelperText>
            )}
            {isSuccess && data?.error !== null && (
              <FormHelperText>{data?.error.detail}</FormHelperText>
            )}
            {!isSuccess && error && (
              <FormHelperText>Kunne ikke bekrefte kode.</FormHelperText>
            )}
          </FormControl>
          <Button
            data-cy="confirm-promo-code-button"
            disabled={verifiedPromoCode || isLoading}
            onClick={() => {
              if (promoCode !== "")
                verifyPromoCode({ storeName: storeOption.id, promoCode });
            }}
            className={styles.promoCodeButton}
            variant="outlined"
          >
            {isLoading ? "Sjekker kode..." : "Bekreft kode"}
          </Button>
        </Box>
      )}
    </div>
  );
};
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
    flexDirection: "row",
  },
  termsContainer: {
    maxWidth: "50%",
    padding: 20,
    backgroundColor: "#F7F7F7",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  acceptTermsButton: {
    marginTop: 10,
  },
  promoCodeContainer: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      width: "50%",
      marginLeft: 20,
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },
  promoCodeButton: {
    height: 48,
    marginTop: 5,
  },
}));

export default OrderTerms;
