import { FunctionComponent } from 'react'
import { Button, makeStyles, Theme, Typography } from '@material-ui/core'
import { format, getMonth } from 'date-fns'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { nb } from 'date-fns/locale'

export type Props = {
    month: number,
    year: number,
    nextMonthDisabled: boolean,
    handlePreviousMonth: () => void,
    handleNextMonth: () => void,
}

/**
 * Component for navigation through months.
 */
const CalendarMonthNavigation: FunctionComponent<Props> = ({ month, year, nextMonthDisabled, handlePreviousMonth, handleNextMonth }) => {
    const styles = useStyles()

    const isThisMonth = month === getMonth(new Date())
    const monthName = format(new Date(year, month), 'MMMM', { locale: nb })

    return (
        <div className={styles.root}>
            <Button id='previous-month' disabled={isThisMonth} onClick={handlePreviousMonth} ><ArrowBackIcon /></Button>
            <Typography className={styles.text} variant='h4'>{monthName}</Typography>
            <Button id='next-month' disabled={nextMonthDisabled} onClick={handleNextMonth}><ArrowForwardIcon /></Button>
        </div>
    )
}
const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        margin: 'auto',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginBottom: 10,
        }
    },
    text: {
        textTransform: 'capitalize',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.4rem',
        }
    }
}))

export default CalendarMonthNavigation