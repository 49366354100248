import { FunctionComponent } from "react";
import {
  Button,
  makeStyles,
  MobileStepper,
  Step,
  StepLabel,
  Stepper,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

export type Props = {
  activeStep: number;
  steps: string[];
  disableNextStep: boolean;
  disablePreviousStep: boolean;
  confirmOrderstep: boolean;
  handleNext: () => void;
  handleBack: () => void;
  handleSubmit: () => void;
};

const StepperHeader: FunctionComponent<Props> = ({
  activeStep,
  steps,
  disableNextStep,
  disablePreviousStep,
  confirmOrderstep,
  handleNext,
  handleBack,
  handleSubmit,
}) => {
  const styles = useStyles();
  const mobile = useMediaQuery(useTheme().breakpoints.down("sm"));

  const nextButton = confirmOrderstep ? (
    <Button
      variant="contained"
      color="primary"
      disabled={disableNextStep}
      onClick={handleSubmit}
      style={{ color: "#fff" }}
    >
      Send bestilling
    </Button>
  ) : (
    <Button
      variant="contained"
      color="primary"
      disabled={disableNextStep}
      onClick={handleNext}
      style={{ color: "#fff" }}
    >
      Neste
    </Button>
  );

  return (
    <div className={styles.root}>
      {mobile ? (
        <MobileStepper
          activeStep={activeStep}
          steps={steps.length}
          variant="text"
          nextButton={nextButton}
          className={styles.mobileStepper}
          backButton={
            <Button disabled={disablePreviousStep} onClick={handleBack}>
              Tilbake
            </Button>
          }
        />
      ) : (
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel data-cy={label} {...labelProps}>
                  {label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      )}
    </div>
  );
};
const useStyles = makeStyles(() => ({
  root: {
    "& .MuiStepIcon-text": {
      fill: "white",
      fontSize: "0.8rem",
      fontWeight: 600,
    },
    "& .MuiStepLabel-label": {
      fontSize: "0.9rem",
      fontWeight: 400,
      marginTop: 12,
    },
  },
  mobileStepper: {
    "& button": {
      fontSize: "1.1rem",
    },
  },
}));

export default StepperHeader;
