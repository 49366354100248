import {
  Dialog,
  DialogProps,
  IconButton,
  Theme,
  useMediaQuery,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import clsx from "clsx";
import { ReactNode } from "react";

import useStyles from "./Modal.styles";

export type Props = {
  children: ReactNode;
  openModal: boolean;
  maxWidth: DialogProps["maxWidth"]; // from Dialog prop Types
  toggleModal: () => void;
};

/**
 * Component representing a standard modal.
 */
const Modal = ({ children, openModal, maxWidth, toggleModal }: Props) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );

  const styles = useStyles();

  let sizeClasses = styles.large;
  if (maxWidth === "md") sizeClasses = styles.medium;
  if (maxWidth === "sm") sizeClasses = styles.small;
  if (maxWidth === "xs") sizeClasses = styles.xsmall;

  return (
    <Dialog
      fullScreen={isMobile}
      onClose={toggleModal}
      open={openModal}
      fullWidth={true}
      classes={{
        paper: clsx(
          styles.paper,
          isMobile ? "" : sizeClasses,
          isMobile ? styles.mobilePaper : null
        ),
      }}
      maxWidth={maxWidth}
    >
      <div className={styles.closeContainer}>
        <IconButton onClick={toggleModal} className={styles.closeButton}>
          <Close />
        </IconButton>
      </div>

      <div className={maxWidth === "lg" ? styles.largeContent : ""}>
        {children}
      </div>
    </Dialog>
  );
};

export default Modal;
