import { FunctionComponent, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { getAnalytics, logEvent } from "firebase/analytics";

import BookingErrorCard from "../../components/Cards/BookingErrorCard/BookingErrorCard";
import ConfirmBooking from "./StepperContentContainers/ConfirmBooking/ConfirmBooking";
import ContactInfo from "./StepperContentContainers/ContactInfo/ContactInfo";
import BookingSummary from "./StepperContentContainers/BookingSummary/BookingSummary";
import OrderTerms from "./StepperContentContainers/OrderTerms/OrderTerms";
import ServiceInfo from "./StepperContentContainers/ServiceInfo/ServiceInfo";
import StepperHeader from "../../components/Stepper/StepperHeader/StepperHeader";
import StepperNavButtons from "../../components/Stepper/StepperNavButtons/StepperNavButtons";
import { useSelector } from "../../hooks/useSelector";
import { createBooking, getServiceDetails } from "../../state/ducks/booking";
import {
  nextStep,
  previousStep,
  removeValidationErrors,
} from "../../state/ducks/stepper";
import { StepperErrorId } from "../../types/errorTypes";
import CalendarContainer from "./StepperContentContainers/CalendarContainer/CalendarContainer";
import ErrorMessageContainer from "../ErrorMessageContainer";
import firebase from "../../configs/firebase";

type Props = {};

const StepperContainer: FunctionComponent<Props> = () => {
  const dispatch = useDispatch();
  const mobile = useMediaQuery(useTheme().breakpoints.down("sm"));
  const stepNames = [
    "Leveringsbetingelser",
    "Om din service",
    "Velg dato",
    "Kontaktinfo",
    "Bekreft booking",
    "Ferdig",
  ];
  const { activeStep, disableNextStep, disablePreviousStep, stepError } =
    useSelector((state) => state.stepperReducer);
  const { booking, errorMessage, sendingBooking, resources } = useSelector(
    (state) => state.bookingReducer
  );

  useEffect(() => {
    if (stepError) dispatch(removeValidationErrors());
  }, [booking]);

  useEffect(() => {
    dispatch(getServiceDetails());
  }, []);

  useEffect(() => {
    if (errorMessage) {
      const analytics = getAnalytics(firebase);
      logEvent(analytics, "booking_error");
    }
  }, [errorMessage]);

  const activeStore = resources.storeOptions.find(
    (store) => store.id === booking.storeOption.id
  );

  const getStep = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
        return <OrderTerms />;
      case 1:
        return <ServiceInfo />;
      case 2:
        return <CalendarContainer />;
      case 3:
        return <ContactInfo />;
      case 4:
        return <ConfirmBooking />;
      case 5:
        return <BookingSummary />;
      default:
        return (
          <BookingErrorCard
            contactEmail={activeStore?.serviceEmail ?? "defaultEmail"}
          />
        );
    }
  };

  const handleNextStep = () => {
    dispatch(nextStep());
  };

  const handlePreviousStep = () => {
    dispatch(previousStep());
  };

  const handleSubmit = () => {
    dispatch(createBooking());
  };

  const lastStep = activeStep === stepNames.length - 1;
  const confirmOrderStep = activeStep === stepNames.length - 2;

  const _disableNextStep =
    sendingBooking || errorMessage !== null || lastStep || disableNextStep;
  const _disablePreviousStep =
    sendingBooking || errorMessage !== null || lastStep || disablePreviousStep;

  return (
    <>
      <div>
        <StepperHeader
          activeStep={activeStep}
          steps={stepNames}
          disableNextStep={_disableNextStep}
          disablePreviousStep={_disablePreviousStep}
          confirmOrderstep={confirmOrderStep}
          handleSubmit={handleSubmit}
          handleNext={handleNextStep}
          handleBack={handlePreviousStep}
        />
        {errorMessage ? (
          <BookingErrorCard
            contactEmail={activeStore?.serviceEmail ?? "defaultEmail"}
          />
        ) : (
          <>
            <Typography variant="h1" gutterBottom>
              Verkstedsbooking
            </Typography>
            {!lastStep && (
              <Typography variant="h2" gutterBottom data-cy="active-step-name">
                {stepNames[activeStep]}
              </Typography>
            )}
            {getStep(activeStep)}
            <ErrorMessageContainer
              exclude={[
                StepperErrorId.noBookingElementBrand,
                StepperErrorId.missingSoldHere,
              ]}
            />
          </>
        )}
      </div>
      {!mobile && (
        <StepperNavButtons
          handleBack={handlePreviousStep}
          handleNext={handleNextStep}
          handleSubmit={handleSubmit}
          confirmOrderstep={confirmOrderStep}
          disableNextStep={_disableNextStep}
          disablePreviousStep={_disablePreviousStep}
        />
      )}
    </>
  );
};

export default StepperContainer;
