import { FunctionComponent } from "react";
import { useDispatch } from "react-redux";

import BookingElementCard from "../../components/Cards/BookingElementCard";
import { useSelector } from "../../hooks/useSelector";
import {
  removeBookingElement,
  setBookingElementInfo,
} from "../../state/ducks/booking";
import { BookingElement } from "../../types/bookingTypes";

type Props = {
  bookingElement: BookingElement;
};

/**
 * Container component for BookingElementCard. Handles logic for removing card and changing input.
 */
const BookingElementCardContainer: FunctionComponent<Props> = ({
  bookingElement,
}) => {
  const dispatch = useDispatch();

  const brandOptions = useSelector(
    (state) => state.bookingReducer.resources.serviceDetails?.brandOptions
  );

  const storeOption = useSelector(
    (state) => state.bookingReducer.booking.storeOption
  );

  const brands = brandOptions?.filter(
    (brand) => brand.type === bookingElement.type
  );

  brands?.push({ id: "other", name: "Annet", type: bookingElement.type });

  const handleRemove = () => {
    dispatch(removeBookingElement(bookingElement.id));
  };

  const handleChangeSelectedBrand = (newValue: string) => {
    if (brands) {
      // Get brand here
      const brand = brands.find((x) => x.id === newValue);

      // Dispatch action
      if (brand)
        dispatch(
          setBookingElementInfo("brand", brand, bookingElement.id, true)
        );
    }
  };

  const handleChangeDescription = (newValue: string) => {
    dispatch(
      setBookingElementInfo("description", newValue, bookingElement.id, true)
    );
  };

  const handleChangeSoldHere = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    dispatch(
      setBookingElementInfo(
        "soldHere",
        value === "false" ? false : true,
        bookingElement.id,
        true
      )
    );
  };

  return (
    <>
      {brands && (
        <BookingElementCard
          bookingElement={bookingElement}
          soldHereTitle={`Denne sykkelen er kjøpt hos ${storeOption.name}`}
          hideRadioButtons={!storeOption.prioritizeSoldBikes}
          onChangeSelectedBrand={handleChangeSelectedBrand}
          onRemove={handleRemove}
          onChangeDescription={handleChangeDescription}
          brands={brands}
          onChangeSoldHere={handleChangeSoldHere}
        />
      )}
    </>
  );
};

export default BookingElementCardContainer;
