import { FunctionComponent, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";

import StepperContainer from "../containers/StepperContainer/StepperContainer";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { verifiedRecaptchaSuccess } from "../state/ducks/booking";
import BaseLayout from "../components/Layouts/BaseLayout/BaseLayout";

const Index: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
    } else {
      await executeRecaptcha().catch((e) => console.error(e));
      dispatch(verifiedRecaptchaSuccess());
    }
  }, [executeRecaptcha]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  return (
    <BaseLayout>
      <StepperContainer />
    </BaseLayout>
  );
};

export default Index;
