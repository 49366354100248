import { Middleware, MiddlewareAPI } from "redux";
import { isRejectedWithValue } from "@reduxjs/toolkit";
import {
  createApi,
  fetchBaseQuery,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/dist/query/react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../configs/firebase";

export type ApiError = {
  type: string;
  title: string;
  status: number;
  detail: string;
  instance: string;
};
export type ApiResult<ResultType> = { result: ResultType; error: ApiError };
// const baseQuery = fetchBaseQuery({
//       responseHandler: async (response: Response) => {
//         if (response.ok) {
//           const responseData = await response.json();
//           if (responseData.error) throw new Error(responseData.error.title);
//           return response.json();
//         } else {
//           throw Error(response.statusText);
//         }
//       },
//     })
const rawBaseQuery = (baseUrl: string) =>
  fetchBaseQuery({
    baseUrl,
    responseHandler: async (response: Response) => {
      if (response.ok) {
        const responseData = await response.json();
        if (responseData.error) throw new Error(responseData.error.title);
        return responseData;
      } else {
        throw Error(response.statusText);
      }
    },
  });

export const baseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args: any, api: any, extraOptions: any) => {
  const bookingInfoRef = doc(db, "public", "bookingInfo");
  const bookingInfoSnap = await getDoc(bookingInfoRef);
  if (bookingInfoSnap.exists()) {
    const bookingInfo = bookingInfoSnap.data();
    return rawBaseQuery(bookingInfo.apiURL)(args, api, extraOptions);
  } else {
    return {
      error: {
        status: 400,
        statusText: "Bad Request",
        data: "No API URL found.",
      },
    };
  }
};
export const api = createApi({
  reducerPath: "api",
  baseQuery,
  endpoints: () => ({}),
  tagTypes: ["Booking"],
});

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action)) {
      api.dispatch({
        type: "FETCH_FAILED",
        payload: {
          error: action.payload,
        },
      });
    }

    return next(action);
  };
