import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  root: {
    display: "flex",
    padding: 30,
    flexDirection: "column",
  },
  titleBox: {
    display: "flex",
    marginBottom: 10,
  },
}));
