import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { makeStyles, TextField } from "@material-ui/core";
import _debounce from "lodash/debounce";

type Props = {
  initValue: string;
  placeholder: string;
  name: string;
  label: string;
  onChange: (value: string) => void;
  type?: string;
  required?: boolean;
  rows?: number;
  maxRows?: number;
  dataCy?: string;
};

const MultilineInput: FunctionComponent<Props> = ({
  initValue,
  placeholder,
  name,
  label,
  onChange,
  type = "text",
  required,
  rows,
  maxRows,
  dataCy,
}) => {
  const styles = useStyles();

  const [value, setValue] = useState(initValue);

  const handleChange = (event: any) => {
    setValue(event.target.value);
  };

  const debounced = useCallback(
    _debounce(() => {
      onChange(value);
    }, 500),
    [value]
  );

  useEffect(() => {
    debounced();

    // Clean up on unmounting
    return debounced.cancel;
  }, [value, debounced]);

  return (
    <div className={styles.root}>
      <TextField
        className={styles.root}
        //classes={{ root: 'background-color: white' }}
        type={type}
        variant="outlined"
        value={value}
        name={name}
        label={label}
        autoComplete={name}
        onChange={handleChange}
        multiline
        minRows={rows ?? 4}
        data-cy={dataCy}
        maxRows={maxRows ?? 6}
      />
    </div>
  );
};
const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  input: {
    width: "100%",
  },
}));

export default MultilineInput;
