import { ChangeEvent, FunctionComponent } from "react";
import { makeStyles, Theme, FormControl } from "@material-ui/core";
import { useDispatch } from "react-redux";
import TextField from "@material-ui/core/TextField";

import { setBookingInfo } from "../../../../state/ducks/booking";
import { useSelector } from "../../../../hooks/useSelector";
import PrivacyDropdown from "../../../../components/Buttons/PrivacyInformation/PrivacyDropdown";
import PhoneNumberInputField from "../../../../components/FormControls/PhoneNumberInputField/PhoneNumberInputField";
import { PhoneNumber } from "../../../../types/bookingTypes";

export type Props = {};

const ContactInfo: FunctionComponent<Props> = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const booking = useSelector((state) => state.bookingReducer.booking);

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatch(setBookingInfo(event.target.name, event.target.value));
  };

  const handleChangePhoneNumber = (value: PhoneNumber) => {
    dispatch(setBookingInfo("customerPhone", value));
  };

  return (
    <div className={styles.root}>
      <div className={styles.columnLeft}>
        <FormControl>
          <TextField
            variant="outlined"
            id="customer-firstname-input"
            data-cy="customer-firstname-field"
            name="firstname"
            label="Fornavn"
            autoComplete="given-name"
            value={booking.firstname}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl>
          <TextField
            variant="outlined"
            id="customer-surname-input"
            data-cy="customer-surname-field"
            name="surname"
            label="Etternavn"
            autoComplete="family-name"
            value={booking.surname}
            onChange={handleChange}
          />
        </FormControl>

        <PhoneNumberInputField
          initValue={booking.customerPhone}
          onChange={handleChangePhoneNumber}
        />
        <FormControl>
          <TextField
            name="customerEmail"
            data-cy="customer-email-field"
            variant="outlined"
            placeholder="Email-addresse"
            autoComplete="email"
            label="Email-addresse"
            value={booking.customerEmail}
            onChange={handleChange}
          />
        </FormControl>
      </div>
      <div>
        <PrivacyDropdown />
      </div>
    </div>
  );
};
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "grid",
    alignItems: "start",
    gridTemplateColumns: "1fr 1fr",
    gridGap: 30,
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
    },
  },
  columnLeft: {
    display: "grid",
    gridGap: 10,
  },
}));

export default ContactInfo;
