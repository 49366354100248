import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  paper: {
    borderRadius: 8,
    overflowY: "scroll",
  },
  mobilePaper: {
    borderRadius: 0,
  },
  large: {
    maxWidth: 1300,
  },
  medium: {
    maxWidth: 600,
  },
  small: {
    maxWidth: 460,
  },
  xsmall: {
    maxWidth: 360,
  },
  closeContainer: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
  closeButton: {
    zIndex: 200,
    position: "fixed",
    marginLeft: -20,
    marginRight: 20,
    marginTop: 20,
    backgroundColor: "rgba(200,200,200,0.4)",
  },
  largeContent: {
    height: "87vh",
    [theme.breakpoints.down("lg")]: {
      height: "95vh",
    },
  },
}));
