import { FunctionComponent } from "react";
import { useSelector } from "../../hooks/useSelector";
import { StepperErrorId } from "../../types/errorTypes";
import { FormHelperText } from "@material-ui/core";

type Props = {
  errorId: StepperErrorId;
};

const FormInlineErrorContainer: FunctionComponent<Props> = ({ errorId }) => {
  const error = useSelector((state) => state.stepperReducer.stepError);

  const includedErrors = [errorId];

  return (
    <>
      {error && includedErrors.includes(error.id) && (
        <FormHelperText data-cy="form-inline-error" error>
          {error.description}
        </FormHelperText>
      )}
    </>
  );
};

export default FormInlineErrorContainer;
