import { FunctionComponent } from 'react'
import { useDispatch } from 'react-redux'

import CalendarBlob from '../../components/Calendar/CalendarBlob/CalendarBlob'
import { setDeliveryDate } from '../../state/ducks/booking'

type Props = {
    date: Date,
    available: number | null,
    selected: boolean,
}

/**
 * Container component for the CalendarBlob component. Handles logic for pushing click handlers to state.
 */
const CalendarBlobContainer: FunctionComponent<Props> = ({ date, available, selected }) => {
    const dispatch = useDispatch()

    const handleClick = () => {
        dispatch(setDeliveryDate(date))
    }

    return (
        <CalendarBlob
            date={date}
            availableResources={available}
            selected={selected}
            onClick={handleClick}
        />
    )
}

export default CalendarBlobContainer