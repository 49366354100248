import { FunctionComponent } from "react";
import { makeStyles, Theme } from "@material-ui/core";

import BookingElementCardContainer from "../../../containers/BookingElementCardContainer/BookingElementCardContainer";
import AddBookingElementButton from "../../Buttons/AddBookingElementButton/AddBookingElementButton";
import {
  BookingElement,
  BookingElementType,
} from "../../../types/bookingTypes";

export type Props = {
  bookingElements: BookingElement[];
  handleAddBookingElement: (type: BookingElement["type"]) => void;
};

const BookingElementGrid: FunctionComponent<Props> = ({
  bookingElements,
  handleAddBookingElement,
}) => {
  const styles = useStyles();

  // Hente fra Firestore
  const types: BookingElementType[] = [
    {
      name: "bike",
      addButtonText: "+ Legg til sykkel",
    },
    // {
    //     name: 'wheel',
    //     addButtonText: '+ Legg til hjul'
    // },
  ];

  return (
    <div className={styles.root} data-cy="booking-element-grid">
      {bookingElements.map((bookingElement) => (
        <BookingElementCardContainer
          key={bookingElement.id}
          bookingElement={bookingElement}
        />
      ))}
      {bookingElements.length < 4 && (
        <AddBookingElementButton
          types={types}
          handleAddBookingElement={(event) =>
            handleAddBookingElement(event.currentTarget.value)
          }
        />
      )}
    </div>
  );
};
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridGap: 15,
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
      gridTemplateRows: "1fr",
      gap: 15,
    },
  },
}));

export default BookingElementGrid;
