import { FunctionComponent } from 'react'
import { Button, makeStyles } from '@material-ui/core'

export type Props = {
    disableNextStep: boolean,
    disablePreviousStep: boolean,
    confirmOrderstep: boolean,
    handleNext: () => void,
    handleBack: () => void,
    handleSubmit: () => void,
}

const StepperNavButtons: FunctionComponent<Props> = ({ disableNextStep, disablePreviousStep, confirmOrderstep, handleNext, handleBack, handleSubmit }) => {
    const styles = useStyles()

    return (
        <div className={styles.root}>
            <Button
                className={styles.backButton}
                onClick={handleBack}
                disabled={disablePreviousStep}
                data-cy='previous-step'
                aria-label='Forrige steg'
            >
                Tilbake
            </Button>
            {confirmOrderstep ?
                <Button
                    variant='contained'
                    color='primary'
                    className={styles.nextButton}
                    disabled={disableNextStep}
                    onClick={handleSubmit}
                    data-cy='submit-step'
                    aria-label='Send bestilling'
                >
                    Send bestilling
                </Button>
                :
                <Button
                    variant='contained'
                    color='primary'
                    className={styles.nextButton}
                    onClick={handleNext}
                    disabled={disableNextStep}
                    data-cy='next-step'
                    aria-label='Neste steg'
                >
                    Neste
                </Button>
            }
        </div>
    )
}
const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 20,
        height: 45,
    },
    nextButton: {
        color: 'white',
    },
    backButton: {
        color: '#383838',
        fontWeight: 400,
    }
}))

export default StepperNavButtons