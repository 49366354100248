import { FunctionComponent } from "react";
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
} from "@material-ui/core";
import { getDaysInMonth } from "date-fns";
import {
  getDatesOfMonth,
  getFirstWeekdayOfMonth,
  isSameDay,
} from "../../../utils/utils";
import CalendarBlobContainer from "../../../containers/CalendarBlobContainer/CalendarBlobContainer";
import { CapacityDate } from "../../../types/calendarTypes";

export type Props = {
  month: number;
  year: number;
  serviceCapacity: CapacityDate[];
  selectedDate: Date | null;
};

/**
 * View component for drawing a calendar using MaterialUI-tables.
 */
const CalendarTable: FunctionComponent<Props> = ({
  month,
  year,
  serviceCapacity,
  selectedDate,
}) => {
  const styles = useStyles();

  // Number of days in month
  // TODO: move this to the container
  const daysInMonth = getDaysInMonth(new Date(year, month));
  const firstDayOfMonth = getFirstWeekdayOfMonth(month, year);
  const dateObjects = getDatesOfMonth(month, year);

  // Blank slots
  let blanks = [];
  for (let i = 1; i < firstDayOfMonth; i++) {
    blanks.push(
      <TableCell key={i + "_blank"} style={{ border: "none" }}>
        <span></span>
      </TableCell>
    );
  }

  // Filled slots
  let days = [];
  for (let i = 1; i <= daysInMonth; i++) {
    const available: number | null = serviceCapacity[i - 1].capacity; // === null ? 0 : serviceCapacity[i - 1].capacity as number
    days.push(
      <TableCell
        key={i + "_day"}
        align="center"
        className={styles.calendarCell}
      >
        <CalendarBlobContainer
          date={dateObjects[i - 1]}
          available={available}
          selected={
            selectedDate ? isSameDay(selectedDate, dateObjects[i - 1]) : false
          }
        />
      </TableCell>
    );
  }

  // Merging blank and filled slots
  let arr = [...blanks, ...days];

  let week = []; // Each cell in a week, gets pushed into rows
  let allRows = []; // All the rows of a month (or weeks)
  let dayNumber = 1; // Day of week, gets resetted to 0 when reaching 8
  for (let i = 1; i <= arr.length; i++, dayNumber++) {
    if (dayNumber % 8 === 0) {
      // If we have reached the end of the week, push the cells to a row
      allRows.push(<TableRow key={"row_" + i + "_" + month}>{week}</TableRow>);
      dayNumber = 0;
      week = []; // Resetting cells
      i--; // Making sure we dont skip a day
    } else {
      // Push item in array to cell
      week.push(arr[i - 1]);
    }
    if (i === arr.length) {
      // If we have reached the end of the array, push the remaining cells
      allRows.push(<TableRow key={"row_" + i}>{week}</TableRow>);
    }
  }

  // Weekdays in header
  let weekdays = ["ma.", "ti.", "on.", "to.", "fr.", "lø.", "sø."];
  let weekdaysName = weekdays.map((day) => {
    return (
      <TableCell className={styles.calendarCell} align="center" key={day}>
        {day}
      </TableCell>
    );
  });

  return (
    <Table padding="checkbox" className={styles.table}>
      <TableHead>
        <TableRow>{weekdaysName}</TableRow>
      </TableHead>
      <TableBody>{allRows}</TableBody>
    </Table>
  );
};
const useStyles = makeStyles((theme: Theme) => ({
  table: {
    maxWidth: 750,
    margin: "auto",
    [theme.breakpoints.down("md")]: {
      maxWidth: 450,
    },
  },
  calendarCell: {
    border: "none",
    padding: "3px !important",
    [theme.breakpoints.down("sm")]: {
      padding: "2px !important",
    },
  },
}));

export default CalendarTable;
