import { FunctionComponent, useEffect } from "react";
import { makeStyles, Theme, Typography } from "@material-ui/core";
import { getAnalytics, logEvent } from "firebase/analytics";

import { useSelector } from "../../../../hooks/useSelector";
import BookingInfoTables from "../../../../components/Tables/BookingInfoTables/BookingInfoTables";
import firebase from "../../../../configs/firebase";

export type Props = {};

const BookingSummary: FunctionComponent<Props> = () => {
  const styles = useStyles();

  const { booking, resources, promoCode } = useSelector(
    (state) => state.bookingReducer
  );

  useEffect(() => {
    const analytics = getAnalytics(firebase);
    logEvent(analytics, "confirmed_booking");
  }, []);

  return (
    <div className={styles.root} data-cy="booking-confirmed-container">
      <div className={styles.confirmedTextContainer}>
        <Typography variant="h2" gutterBottom>
          Booking bekreftet &#9989;
        </Typography>
        <Typography variant="body1" className={styles.confirmedText}>
          {resources.confirmedBookingMessage}
        </Typography>
      </div>
      <BookingInfoTables
        booking={booking}
        promoCode={promoCode?.name ?? null}
      />
    </div>
  );
};
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  confirmedText: {},
  confirmedTextContainer: {
    maxWidth: "50ch",
    padding: 15,
    backgroundColor: "#F7f7f7",
    borderRadius: 5,
    marginBottom: 10,
  },
  exitButton: {
    marginTop: 10,
    alignSelf: "flex-end",
    color: "white",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 15px",
    },
  },
}));

export default BookingSummary;
