import { BookingElement } from '../types/bookingTypes';

export const validateBookingElement = (bookingElement: unknown): bookingElement is BookingElement => {
    if (typeof bookingElement === 'object') {
        const test = bookingElement as BookingElement
        return (
            typeof test?.id === 'string' &&
            test?.id !== '' &&
            typeof test.description === 'string' &&
            typeof test.type === 'string' &&
            test.brand !== null
        )
    }
    else return false
}