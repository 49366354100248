import { BookingDTO } from "../types/bookingTypes";
import { api, ApiResult } from "./api";

export const mutateBookingsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getBooking: builder.query<
      ApiResult<BookingDTO>,
      { hashedId: string; storeName: string }
    >({
      query: ({ hashedId, storeName }) =>
        `/serviceBookings/${hashedId}?storeName=${storeName}`,
      transformResponse: (response: ApiResult<BookingDTO>, meta, args) => {
        return response;
      },
      providesTags: ["Booking"],
    }),
    updateBooking: builder.mutation<
      any,
      { booking: BookingDTO; storeName: string }
    >({
      query: ({ booking, storeName }) => ({
        url: `/serviceBookings/${booking.hashedId!}?storeName=${storeName}`,
        method: "PUT",
        body: booking,
      }),
      invalidatesTags: ["Booking"],
    }),
    deleteBooking: builder.mutation<
      any,
      { hashedId: string; storeName: string }
    >({
      query: ({ hashedId, storeName }) => ({
        url: `/serviceBookings/${hashedId}?storeName=${storeName}&hardDelete=true`,
        method: "DELETE",
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetBookingQuery,
  useUpdateBookingMutation,
  useDeleteBookingMutation,
} = mutateBookingsApi;
