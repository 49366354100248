import { FunctionComponent } from "react";
import {
  Box,
  CircularProgress,
  makeStyles,
  Typography,
} from "@material-ui/core";

import CalendarMonthNavigationContainer from "../../../containers/CalendarMonthNavigationContainer/CalendarMonthNavigationContainer";
import CalendarTable from "../CalendarTable/CalendarTable";
import { useSelector } from "../../../hooks/useSelector";
import { CapacityDate } from "../../../types/calendarTypes";
import LoadingSpinner from "../../Indicators/LoadingSpinner/LoadingSpinner";

export type Props = {
  serviceCapacity: CapacityDate[] | null;
  loadingServiceCapacity: boolean;
  month: number;
  year: number;
};

const BookingCalendar: FunctionComponent<Props> = ({
  serviceCapacity,
  loadingServiceCapacity,
  month,
  year,
}) => {
  const styles = useStyles();
  const selectedDate = useSelector(
    (state) => state.bookingReducer.booking.deliveryDate
  );
  const deliveryDate = selectedDate ? new Date(selectedDate) : null;

  return (
    <div className={styles.root}>
      {serviceCapacity && !loadingServiceCapacity ? (
        <>
          <CalendarMonthNavigationContainer />
          <CalendarTable
            month={month}
            year={year}
            serviceCapacity={serviceCapacity}
            selectedDate={deliveryDate}
          />
        </>
      ) : (
        <LoadingSpinner text="Henter kalender..." />
      )}
    </div>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 600,
    margin: "auto",
  },
  loadingBox: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#f9f9f9",
    borderRadius: 10,
    height: "100%",
  },
  loadingText: {
    marginTop: 10,
  },
}));

export default BookingCalendar;
