import { Box, Card, Typography } from "@material-ui/core";
import { Error } from "@material-ui/icons";
import { ReactNode } from "react";
import useStyles from "./ErrorCard.styles";

export type Props = {
  title: string;
  detail: string;
  inline?: boolean;
  icon?: ReactNode;
};

const ErrorCard = ({ title, detail, inline, icon }: Props) => {
  const styles = useStyles();

  return (
    <Card className={styles.root} elevation={inline ? 0 : 2}>
      <Box className={styles.titleBox}>
        {icon !== undefined ? icon : <Error color="error" />}
        <Typography variant="h2" color="error" style={{ marginLeft: 10 }}>
          {title}
        </Typography>
      </Box>
      <Typography>{detail}</Typography>
    </Card>
  );
};

export default ErrorCard;
