import { makeStyles } from "@material-ui/core";

type Props = {
  logoPath: string
}


const Logo = ({logoPath}: Props) => {
  const classes = useStyles();

  return <img className={classes.logo} src={logoPath} alt="Store Logo" />;
};

const useStyles = makeStyles((theme) => ({
  logo: {
    display: "block",
    width: "100%",
  },
}));

export default Logo;
