import { ThemeProvider } from "@material-ui/core";
import { getAnalytics } from "firebase/analytics";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Provider } from "react-redux";
import { doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";

import app, { db } from "./configs/firebase";
import Navbar from "./components/Navbar/Navbar";
import store from "./state/store";
import getTheme from "./theme";
import Index from "./views/Index";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ChangeBooking from "./views/ChangeBooking";
import NotFound from "./views/NotFound";
import { SystemConfig } from "./types/bookingTypes";
import LoadingSpinner from "./components/Indicators/LoadingSpinner/LoadingSpinner";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <Index />
      </>
    ),
    errorElement: <NotFound />,
  },
  {
    path: "/:storeName/:id",
    element: <ChangeBooking />,
    errorElement: <NotFound />,
  },
]);

function App() {
  getAnalytics(app);

  const [config, setConfig] = useState<SystemConfig | null>(null);

  const getConfigValues = async () => {
    try {
      const configRef = doc(db, "public", "systemInfo");
      const configDoc = await getDoc(configRef);

      if (configDoc.exists()) {
        setConfig(configDoc.data() as SystemConfig);
      } else {
        console.log("No such document!");
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getConfigValues();
  }, []);

  if (!config) return <LoadingSpinner />;

  return (
    <Provider store={store}>
      <ThemeProvider theme={getTheme(config.primaryColor)}>
        <GoogleReCaptchaProvider reCaptchaKey={config.recaptchaSiteKey}>
          <Navbar showSSCLogo={config.showSSCLogo} />
          <RouterProvider router={router} />
        </GoogleReCaptchaProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
