import { api, ApiResult } from "./api";

export const promoCodeApi = api.injectEndpoints({
  endpoints: (builder) => ({
    postPromoCode: builder.mutation<
      ApiResult<{ id: string; name: string }>,
      { storeName: string; promoCode: string }
    >({
      query: ({ storeName, promoCode }) => ({
        url: `/servicePromotionalCode`,
        method: "POST",
        body: { storeName, promotionalCode: promoCode },
      }),
      transformResponse: (
        response: ApiResult<{ id: string; name: string }>,
        meta,
        args
      ) => {
        return response;
      },
    }),
  }),
});

export const { usePostPromoCodeMutation } = promoCodeApi;
