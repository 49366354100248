import { FunctionComponent, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { useSelector } from '../../../../hooks/useSelector'
import BookingCalendar from '../../../../components/Calendar/BookingCalendar/BookingCalendar'
import { getServiceCapacity } from '../../../../state/ducks/booking'
import { filterServiceCapacityOnMonth } from '../../../../utils/utils'

type Props = {

}

const CalendarContainer: FunctionComponent<Props> = () => {
    const dispatch = useDispatch()
    const { month, year } = useSelector(state => state.calendarReducer)
    const { resources, booking } = useSelector(state => state.bookingReducer)

    useEffect(() => {
        dispatch(getServiceCapacity(booking.bookingElements))
    }, [booking.bookingElements])

    const serviceCapacity = resources.serviceCapacity === null ? null : filterServiceCapacityOnMonth(resources.serviceCapacity, month)

    return (
        <>
            <BookingCalendar
                serviceCapacity={serviceCapacity}
                loadingServiceCapacity={resources.loadingServiceCapacity}
                month={month}
                year={year}
            />
        </>
    )
}

export default CalendarContainer