import { getYear, getMonth } from 'date-fns'
import { AppThunk } from '../../types/reduxTypes'
import { getMaxMonthAvailable } from '../../utils/utils'

const SET_MONTH = 'SET_MONTH'
const SET_YEAR = 'SET_YEAR'

const setMonth = (month: number) => ({
    type: SET_MONTH,
    payload: { month }
})

const setYear = (year: number) => ({
    type: SET_YEAR,
    payload: { year }
})

export const setSelectedMonth = (newMonth: number): AppThunk => async (dispatch, getState) => {
    const { year } = getState().calendarReducer

    // Set right month
    if (newMonth === 12) {
        dispatch(setMonth(0))
        dispatch(setYear(year + 1))
    } else if (newMonth === -1) {
        dispatch(setMonth(11))
        dispatch(setYear(year - 1))
    } else {
        dispatch(setMonth(newMonth))
    }
}

type InitialState = {
    selectedDate: Date | null,
    month: number,
    year: number,
    maxMonthAvailable: number,
    nextMonthDisabled: boolean,
}

const initialState: InitialState = {
    selectedDate: null,
    month: getMonth(new Date()),
    year: getYear(new Date()),
    maxMonthAvailable: 3,
    nextMonthDisabled: false,
}

export default function calendarReducer(state = initialState, action: any) {
    const { payload, type } = action
    switch (type) {
        case SET_MONTH:
            const nextMonthAvailable = getMaxMonthAvailable(state.maxMonthAvailable)
            const nextMonthDisabled = nextMonthAvailable === payload.month

            return {
                ...state,
                month: payload.month,
                nextMonthDisabled,
            }
        case SET_YEAR:
            return {
                ...state,
                year: payload.year,
            }
        default:
            return state
    }
}