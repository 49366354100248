import { FunctionComponent } from 'react'
import { makeStyles } from '@material-ui/core'

import BookingElementGridContainer from '../../../../containers/BookingElementGridContainer/BookingElementGridContainer'

export type Props = {

}

const ServiceInfo: FunctionComponent<Props> = () => {
    const styles = useStyles()

    return (
        <div className={styles.root}>
            <BookingElementGridContainer />
        </div>
    )
}
const useStyles = makeStyles(() => ({
    root: {

    }
}))

export default ServiceInfo