import { ServiceCapacity } from "../types/calendarTypes";
import { api, ApiResult } from "./api";

export const serviceCapacityApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getServiceCapacity: builder.query<
      ServiceCapacity,
      { storeName: string; brand: string }
    >({
      query: ({ storeName, brand }) =>
        `/serviceCapacity?storeName=${storeName}&brand[]=${brand}`,
    }),
  }),
  overrideExisting: false,
});

export const { useGetServiceCapacityQuery } = serviceCapacityApi;
