import { Box, Button, Typography } from "@material-ui/core";
import { Check } from "@material-ui/icons";
import { useEffect } from "react";
import { useSelector } from "../../../hooks/useSelector";
import { useUpdateBookingMutation } from "../../../services/MutateBooking";

import { useGetServiceCapacityQuery } from "../../../services/ServiceCapacity";
import { BookingDTO } from "../../../types/bookingTypes";
import { filterServiceCapacityOnMonth } from "../../../utils/utils";
import BookingCalendar from "../../Calendar/BookingCalendar/BookingCalendar";
import LoadingSpinner from "../../Indicators/LoadingSpinner/LoadingSpinner";
import Modal from "../Modal";
import makeStyles from "./ChangeDateModal.styles";

type Props = {
  open: boolean;
  toggleModal: () => void;
  storeName: string;
  booking: BookingDTO;
};

const ChangeDateModal = ({ open, toggleModal, storeName, booking }: Props) => {
  const styles = makeStyles();
  const {
    data: serviceCapacityData,
    isLoading: loadingServiceCapacity,
    isSuccess: loadingServiceCapacitySuccess,
  } = useGetServiceCapacityQuery({
    storeName,
    brand: booking.brand.id,
  });

  const [
    updateBooking,
    { isLoading: sendingBooking, isSuccess: sendingBookingSuccess, reset },
  ] = useUpdateBookingMutation();

  const { month, year } = useSelector((state) => state.calendarReducer);

  const { deliveryDate } = useSelector((state) => state.bookingReducer.booking);

  const serviceCapacity = serviceCapacityData
    ? filterServiceCapacityOnMonth(serviceCapacityData, month)
    : null;

  const handleUpdateBooking = () => {
    if (deliveryDate) {
      updateBooking({
        booking: {
          ...booking,
          deliveryDate,
        },
        storeName,
      });
    }
  };

  useEffect(() => {
    if (sendingBookingSuccess) {
      setTimeout(() => {
        toggleModal();
        setTimeout(reset, 1000);
      }, 1500);
    }
  }, [sendingBooking]);

  return (
    <Modal openModal={open} toggleModal={toggleModal} maxWidth="md">
      <Box style={{ padding: 30, minHeight: 300 }}>
        {sendingBooking && !sendingBookingSuccess && (
          <LoadingSpinner text="Endrer dato på bestillingen" />
        )}
        {!sendingBooking && sendingBookingSuccess && (
          <Box className={styles.successBox}>
            <Typography variant="h2" style={{ marginRight: 5 }}>
              Dato endret!
            </Typography>
            <Check color="secondary" fontSize="default" />
          </Box>
        )}
        {!sendingBooking && !sendingBookingSuccess && (
          <>
            <Typography variant="h2" style={{ marginBottom: 30 }}>
              Velg ny dato
            </Typography>
            <BookingCalendar
              serviceCapacity={serviceCapacity}
              loadingServiceCapacity={
                serviceCapacity === null || loadingServiceCapacity
              }
              month={month}
              year={year}
            />
            {!loadingServiceCapacity && !loadingServiceCapacitySuccess && (
              <Typography variant="h2">Noe gikk galt</Typography>
            )}
            <Box className={styles.buttonBox}>
              <Button
                variant="outlined"
                onClick={() => toggleModal()}
                disabled={sendingBooking}
              >
                Avbryt
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{ color: "white" }}
                onClick={handleUpdateBooking}
                disabled={sendingBooking}
              >
                Bekreft ny dato
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default ChangeDateModal;
