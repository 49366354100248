import { Box, Button, Typography } from "@material-ui/core";
import { FunctionComponent, useState } from "react";
import { useParams } from "react-router-dom";
import ErrorCard from "../components/Feedback/ErrorCard/ErrorCard";
import LoadingSpinner from "../components/Indicators/LoadingSpinner/LoadingSpinner";

import BaseLayout from "../components/Layouts/BaseLayout/BaseLayout";
import CancelBookingModal from "../components/Modals/CancelBookingModal/CancelBookingModal";
import ChangeDateModal from "../components/Modals/ChangeDateModal/ChangeDateModal";
import BookingInfoTables from "../components/Tables/BookingInfoTables/BookingInfoTables";
import { useGetBookingQuery } from "../services/MutateBooking";

const ChangeBooking: FunctionComponent = () => {
  const { id, storeName } = useParams();
  const [showNewDateModal, setShowNewDateModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);

  const { data, isLoading, isSuccess } = useGetBookingQuery(
    { hashedId: id!, storeName: storeName! },
    { skip: !id || !storeName }
  );

  if (data && data.error) {
    return (
      <BaseLayout>
        <ErrorCard inline title={data.error.title} detail={data.error.detail} />
      </BaseLayout>
    );
  }

  return (
    <BaseLayout>
      <Box>
        <Typography variant="h1" style={{ marginBottom: 20 }}>
          Din booking
        </Typography>
        <>
          {isLoading && <LoadingSpinner text="Henter ordre..." />}
          {isSuccess && data.result && (
            <>
              <Typography variant="h2" style={{ marginBottom: 10 }}>
                Oversikt
              </Typography>
              <BookingInfoTables
                booking={data.result}
                promoCode={data.result.promoCodeId ?? null}
              />
            </>
          )}
        </>
        <Box
          style={{
            marginTop: 15,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="outlined"
            style={{ marginRight: 5 }}
            onClick={() => setShowNewDateModal((prev) => !prev)}
          >
            Endre dato
          </Button>
          <Button
            variant="outlined"
            onClick={() => setShowCancelModal((prev) => !prev)}
          >
            Avbestill time
          </Button>
        </Box>
      </Box>
      {storeName && data && (
        <>
          <CancelBookingModal
            open={showCancelModal}
            toggleModal={() => setShowCancelModal((prev) => !prev)}
            storeName={storeName}
            booking={data.result}
          />
          <ChangeDateModal
            open={showNewDateModal}
            toggleModal={() => setShowNewDateModal((prev) => !prev)}
            storeName={storeName}
            booking={data.result}
          />
        </>
      )}
    </BaseLayout>
  );
};

export default ChangeBooking;
