import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { getFirestore } from "firebase/firestore";
import { getStorage, ref } from "firebase/storage";

import firebaseConfig from "./firebaseConfig.json";

const app = firebase.initializeApp(firebaseConfig);

export const projectId = firebaseConfig.projectId;

export const storage = getStorage(app);
export const publicStorageRef = ref(storage, "public");

export const db = getFirestore(app);

export default app;
