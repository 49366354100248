import { api, ApiResult } from "./api";

export const getBikeboxAvailabilityApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getBikeboxAvailability: builder.query<
      ApiResult<number>,
      {
        storeName: string;
        fromDate?: string;
        toDate: string;
        promoCode?: string;
      }
    >({
      query: ({ storeName, fromDate, toDate, promoCode }) => ({
        url: `/bikeBoxAvailabilityQuantity?storeName=${storeName}&fromDate=${fromDate}&toDate=${toDate}&promoCode=${promoCode}`,
        method: "GET",
      }),
      transformResponse: (response: ApiResult<number>, meta, args) => {
        return response;
      },
    }),
  }),
  overrideExisting: false,
});

export const { useGetBikeboxAvailabilityQuery } = getBikeboxAvailabilityApi;
