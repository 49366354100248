import { StepperError } from "../../types/errorTypes";
import { AppThunk } from "../../types/reduxTypes";
import { validateStepState } from "../../utils/utils";

export const SET_STEP = "SET_STEP";
export const VALID_STEP = "VALID_STEP";
export const INVALID_STEP = "INVALID_STEP";

/**
 * Thunk for validating and handling next step
 */
export const nextStep = (): AppThunk => async (dispatch, getState) => {
  try {
    const state = getState();

    // Validate step
    const validatedStep = validateStepState(state);

    if (typeof validatedStep === "number") {
      dispatch({
        type: SET_STEP,
        payload: { activeStep: state.stepperReducer.activeStep + 1 },
      });
    } else {
      dispatch({
        type: INVALID_STEP,
        payload: { error: validatedStep.error },
      });
    }
  } catch (error) {}
};

/**
 * Thunk for handling previous step
 * TODO: Block previous step in frontend
 */
export const previousStep = (): AppThunk => async (dispatch, getState) => {
  const state = getState().stepperReducer;
  if (state.activeStep === 0) return;
  dispatch({
    type: SET_STEP,
    payload: { activeStep: state.activeStep - 1 },
  });
};

/**
 * Run on state update
 * Should only remove errors, and not add any (this is the nextStep thunk's job).
 * @returns
 */
export const removeValidationErrors = (): AppThunk => async (
  dispatch,
  getState
) => {
  dispatch({
    type: VALID_STEP,
  });

  // Temporary removal, finn en bedre måte å gjøre dette på.
  // const state = getState()

  // // Validate step
  // const validatedStep = validateStepState(state)
  // console.log(validatedStep)

  // if (typeof validatedStep === 'number') {
  //     dispatch({
  //         type: VALID_STEP,
  //     })
  // }

  // if (typeof validatedStep === 'object' && validatedStep.error) {
  //     dispatch({
  //         type: INVALID_STEP,
  //         payload: { error: validatedStep.error }
  //     })
  // } else {
  //     dispatch({
  //         type: VALID_STEP,
  //     })
  // }
};

interface StepperState {
  activeStep: number;
  disableNextStep: boolean;
  disablePreviousStep: boolean;
  stepError: null | StepperError;
}

const initialState: StepperState = {
  activeStep: 0,
  disableNextStep: false,
  disablePreviousStep: false,
  stepError: null,
};

export default function stepperReducer(state = initialState, action: any) {
  const { payload, type } = action;
  switch (type) {
    case SET_STEP:
      return {
        ...state,
        activeStep: payload.activeStep as number,
        stepError: null,
      };
    case VALID_STEP:
      return {
        ...state,
        stepError: null,
      };
    case INVALID_STEP:
      return {
        ...state,
        stepError: payload.error as StepperError,
      };
    default:
      return state;
  }
}
