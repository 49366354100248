import { FunctionComponent } from "react";
import ErrorMessageCard from "../ErrorMessageCard";

export type Props = {
  contactEmail: string;
};

const BookingErrorCard: FunctionComponent<Props> = ({ contactEmail }) => {
  return (
    <ErrorMessageCard
      errorDescription={`Noe gikk galt! Vennligst prøv igjen, eller send en mail til ${contactEmail} for å bestille time.`}
    />
  );
};

export default BookingErrorCard;
