import {
  Box,
  Button,
  Theme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";

import image from "../static/no-page.jpg";
import BaseLayout from "../components/Layouts/BaseLayout/BaseLayout";

export type Props = {};

const NotFound = ({}: Props) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const flexDirection = isMobile ? "column-reverse" : "row";

  const textStyles = isMobile
    ? {
        marginTop: 20,
      }
    : {
        marginRight: 50,
      };
  return (
    <BaseLayout>
      <Box
        display="flex"
        flexDirection={flexDirection}
        alignItems="center"
        justifyContent={"center"}
      >
        <div style={textStyles}>
          <Typography variant="h1">404</Typography>
          <Typography variant="h2">Her skjedde det en feil.</Typography>
          <a href={window.location.origin} style={{ textDecoration: "none" }}>
            <Button
              style={{ color: "white", textDecoration: "none", marginTop: 15 }}
              variant="contained"
              color="primary"
            >
              Gå tilbake til forsiden
            </Button>
          </a>
        </div>
        <img
          src={image}
          alt="Illustrasjonsbilde, sykkel på hodet."
          style={{ borderRadius: 8, maxHeight: "50vh" }}
        />
      </Box>
    </BaseLayout>
  );
};

export default NotFound;
