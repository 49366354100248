import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  buttonBox: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 20,
  },
  successBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 50,
    backgroundColor: "#F2F2F2",
    borderRadius: 8,
  },
}));
