import { FunctionComponent } from "react";
import { CircularProgress, makeStyles, Typography } from "@material-ui/core";

import { useSelector } from "../../../../hooks/useSelector";
import BookingInfoTables from "../../../../components/Tables/BookingInfoTables/BookingInfoTables";
import BikeboxBookingContainer from "../../../BikeboxBookingContainer/BikeboxBookingContainer";
import { useGetBikeboxAvailabilityQuery } from "../../../../services/BikeboxAvailability";

export type Props = {};

const ConfirmBooking: FunctionComponent<Props> = () => {
  const styles = useStyles();

  const { booking, sendingBooking, promoCode } = useSelector(
    (state) => state.bookingReducer
  );

  const { data, isSuccess } = useGetBikeboxAvailabilityQuery(
    {
      storeName: booking.storeOption.id,
      toDate: booking.deliveryDate!, // deliveryDate is always set at this point
      promoCode: promoCode?.name ?? undefined,
    },
    { skip: !booking.deliveryDate || !booking.storeOption.id }
  );

  return (
    <div>
      {sendingBooking && (
        <div className={styles.loadingBox}>
          <Typography variant="h3">Sender booking...</Typography>
          <CircularProgress size={24} />
        </div>
      )}
      <BookingInfoTables
        booking={booking}
        promoCode={promoCode?.name ?? null}
      />
      {isSuccess && data && data.result > 0 && (
        <BikeboxBookingContainer bikeboxQty={data.result} />
      )}
    </div>
  );
};
const useStyles = makeStyles(() => ({
  loadingBox: {
    display: "flex",
    alignItems: "center",
    gap: 10,
    margin: "10px 0",
  },
  table: {
    width: "100%",
    border: "1px solid #e2e2e2",
  },
  tableRowKey: {
    minWidth: 110,
  },
  tableRowKeyNumber: {
    fontWeight: 500,
    color: "#9c9c9c",
  },
}));

export default ConfirmBooking;
