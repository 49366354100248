import { FunctionComponent } from 'react'
import { useDispatch } from 'react-redux'
import CalendarMonthNavigation from '../../components/Calendar/CalendarMonthNavigation/CalendarMonthNavigation'
import { useSelector } from '../../hooks/useSelector'
import { setSelectedMonth } from '../../state/ducks/calendar'

export type Props = {

}

const CalendarMonthNavigationContainer: FunctionComponent<Props> = () => {
    const dispatch = useDispatch()
    const { month, year, nextMonthDisabled } = useSelector(state => state.calendarReducer)

    const handleNextMonth = () => {
        let newMonth = month + 1
        dispatch(setSelectedMonth(newMonth))
    }

    const handlePreviousMonth = () => {
        let newMonth = month - 1
        dispatch(setSelectedMonth(newMonth))
    }

    return (
        <CalendarMonthNavigation
            month={month}
            year={year}
            nextMonthDisabled={nextMonthDisabled}
            handleNextMonth={handleNextMonth}
            handlePreviousMonth={handlePreviousMonth}
        />
    )
}

export default CalendarMonthNavigationContainer