import { FunctionComponent } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";

export type Props = {};

const PrivacyDropdown: FunctionComponent<Props> = () => {
  return (
    <div>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography component="i">Om personvern</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Personopplysningene du oppgir vil kun bli brukt til kommunikasjon
            vedrørende din service. Vi vil ikke benytte denne informasjonen til
            markedsføring eller distribuering. Denne informasjonen blir slettet
            fra vårt bookingsystem én uke etter avsluttet service. Ønsker du å
            få denne informasjonen slettet fra vårt kassesystem, vennligst ta
            kontakt med oss.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default PrivacyDropdown;
