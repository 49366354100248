import { FunctionComponent } from "react";
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Theme,
  Typography,
} from "@material-ui/core";
import { BookingDTO, CustomerBooking } from "../../../types/bookingTypes";
import { nb } from "date-fns/locale";
import { format } from "date-fns";
import BikeboxLogo from "../../../static/bikebox-logo.png";

export type Props = {
  booking: CustomerBooking | BookingDTO;
  promoCode: string | null;
};

function isBookingDTO(
  booking: CustomerBooking | BookingDTO
): booking is BookingDTO {
  return (booking as BookingDTO).id !== undefined;
}

const BookingInfoTables: FunctionComponent<Props> = ({
  booking,
  promoCode,
}) => {
  const styles = useStyles();

  const isCustomerBooking = !isBookingDTO(booking);

  return (
    <div className={styles.root}>
      <Table className={styles.table}>
        <TableBody>
          <TableRow>
            <TableCell>
              <Typography variant="body1">Navn:</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body1">
                {isBookingDTO(booking)
                  ? booking.customerName
                  : booking.firstname.concat(" ", booking.surname)}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="body1">Mobilnummer:</Typography>
            </TableCell>
            <TableCell>
              {isCustomerBooking ? (
                <Typography variant="body1">
                  {`(+${booking.customerPhone.countryCode}) ${booking.customerPhone.nationalNumber}`}
                </Typography>
              ) : (
                <Typography>{booking.customerPhone}</Typography>
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="body1">Email:</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body1">{booking.customerEmail}</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Table className={styles.table}>
        <TableBody>
          {isCustomerBooking && (
            <TableRow>
              <TableCell>
                <Typography variant="body1">Butikk:</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1">
                  {booking.storeOption.name}
                </Typography>
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell className={styles.tableRowKey}>
              <Typography variant="body1">Dato:</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body1">
                {booking.deliveryDate
                  ? format(
                      new Date(booking.deliveryDate),
                      "eeee do LLLL, yyyy",
                      {
                        locale: nb,
                      }
                    )
                  : "---"}
              </Typography>
            </TableCell>
          </TableRow>
          {promoCode && (
            <TableRow>
              <TableCell>
                <Typography variant="body1">Kampanjekode:</Typography>
              </TableCell>
              <TableCell>
                <Typography
                  variant="body1"
                  style={{ textTransform: "uppercase" }}
                >
                  {promoCode}
                </Typography>
              </TableCell>
            </TableRow>
          )}
          {isCustomerBooking && (
            <TableRow>
              <TableCell>
                <Typography variant="body1">Antall:</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1">
                  {booking.bookingElements.length}
                </Typography>
              </TableCell>
            </TableRow>
          )}
          {isCustomerBooking ? (
            booking.bookingElements.map((bookingElement, i) => {
              return (
                <TableRow key={bookingElement.id}>
                  <TableCell>
                    <Typography variant="body1">
                      <span className={styles.tableRowKeyNumber}>
                        {i + 1} –{" "}
                      </span>{" "}
                      {bookingElement.brand?.name}
                    </Typography>
                    {bookingElement.bikebox && (
                      <img height={35} src={BikeboxLogo} />
                    )}
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">
                      {bookingElement.description}
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <>
              <TableRow>
                <TableCell>
                  <Typography variant="body1">Sykkelmerke:</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1">{booking.brand.name}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="body1">Beskrivelse:</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1">
                    {booking.description === "" ? (
                      <i>Ingen beskrivelse</i>
                    ) : (
                      booking.description
                    )}
                  </Typography>
                </TableCell>
              </TableRow>
            </>
          )}
        </TableBody>
      </Table>
    </div>
  );
};
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: 10,
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
    },
  },
  table: {
    border: "1px solid #e2e2e2",
    tableLayout: "fixed",
    "& td": {
      wordWrap: "break-word",
      overflowWrap: "break-word",
    },
  },
  tableRowKey: {},
  tableRowKeyNumber: {
    fontWeight: 500,
    color: "#9c9c9c",
  },
}));

export default BookingInfoTables;
