import { FunctionComponent } from "react";
import { makeStyles, Theme, useTheme } from "@material-ui/core";
import tinycolor from "tinycolor2";

import Logo from "../Graphics/Logo";
import shimanoLogo from "../../static/logo-ssc.svg";
import whiteLogo from "../../static/logo-white.png";
import darkLogo from "../../static/logo-black.png";
import { VERSION } from "../../constants";

export type Props = {
  showSSCLogo: boolean;
};

const Navbar: FunctionComponent<Props> = ({ showSSCLogo }) => {
  const styles = useStyles();
  const theme = useTheme();

  const primaryColor = tinycolor(theme.palette.primary.main);
  const logoPath = primaryColor.isDark() ? whiteLogo : darkLogo;

  return (
    <div className={styles.root}>
      <div className={styles.navbarLogos}>
        <div style={{ width: 150 }}>
          <Logo logoPath={logoPath} />
        </div>
        {showSSCLogo && (
          <img
            className={styles.logo}
            src={shimanoLogo}
            alt="Shimano Service Center logo"
          />
        )}
      </div>
      <p className={styles.versionNumber}>v{VERSION}</p>
    </div>
  );
};
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "relative",
    height: 65,
    backgroundColor: theme.palette.primary.main,
    padding: "10px 30px",
    [theme.breakpoints.up("sm")]: {
      boxShadow: "0px 6px 9px 0px rgb(0 0 0 / 15%)",
    },
  },
  navbarLogos: {
    display: "flex",
    height: "100%",
    justifyContent: "space-around",
    alignItems: "center",
    maxWidth: theme.breakpoints.values.md + 60,
    margin: "auto",
  },
  logo: {
    maxHeight: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "45%",
      height: "auto",
      objectFit: "contain",
    },
  },
  versionNumber: {
    position: "absolute",
    bottom: 3,
    right: 10,
    margin: "0px 30px",
    color: theme.palette.primary.light,
    fontSize: "0.8em",
  },
}));

export default Navbar;
